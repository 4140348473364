import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.tims;

export const appTaskApi = createApi({
  reducerPath: "appTaskApi",
  baseQuery: customFetchBase,
  tagTypes: ["AppTask"],
  endpoints: (builder) => ({
    getAppTasks: builder.query({
      query: () => apiUrl + "/apptask",
      providesTags: ["AppTask"],
    }),
    addAppTask: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/apptask/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AppTask"],
    }),
    updateAppTask: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/apptask/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["AppTask"],
    }),
    partupdateAppTask: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/apptask/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["AppTask"],
    }),
    updateAppTaskSortOrder: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["AppTask"],
    }),
    deleteAppTask: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/apptask/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AppTask"],
    }),
  }),
});

export const {
  useGetAppTasksQuery,
  useAddAppTaskMutation,
  useUpdateAppTaskMutation,
  useUpdateAppTaskSortOrderMutation,
  usePartupdateAppTaskMutation,
  useDeleteAppTaskMutation,
} = appTaskApi;
