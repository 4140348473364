import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const productDiscountApi = createApi({
  reducerPath: 'productDiscountApi',
  baseQuery: customFetchBase,
  tagTypes: ['DiscountType', 'ApplyDiscount', 'ProdDiscount'],
  endpoints: (builder) => ({
    // Disount Type
    getDiscountTypes: builder.query({
      query: () => apiUrl + '/discounttype',
      providesTags: ['DiscountType']
    }),
    addDiscountType: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/discounttype/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['DiscountType']
    }),
    updateDiscountType: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/discounttype/${id}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['DiscountType']
    }),
    updateDiscountTypeSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + '/sortorder/',
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['DiscountType']
    }),
    deleteDiscountType: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/discounttype/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['DiscountType']
    }),
    // Apply Disount
    getApplyDiscounts: builder.query({
      query: () => apiUrl + '/applydiscount',
      providesTags: ['ApplyDiscount']
    }),
    addApplyDiscount: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/applydiscount/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['ApplyDiscount']
    }),
    updateApplyDiscount: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/applydiscount/${id}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['ApplyDiscount']
    }),
    updateApplyDiscountSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + '/sortorder/',
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['ApplyDiscount']
    }),
    deleteApplyDiscount: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/applydiscount/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ApplyDiscount']
    }),
    // Product Disount
    getDiscounts: builder.query({
      query: () => apiUrl + '/proddiscount',
      providesTags: ['ProdDiscount']
    }),
    addDiscount: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/proddiscount/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['ProdDiscount']
    }),
    updateDiscount: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/proddiscount/${id}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['ProdDiscount']
    }),
    updateDiscountSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + '/sortorder/',
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['ProdDiscount']
    }),
    deleteDiscount: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/proddiscount/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ProdDiscount']
    }),
    getCustomerDiscountInfo: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/customerdiscountinfo/',
        method: 'POST',
        body: formData
      })
    })
  })
});

export const {
  // Discount Type
  useGetDiscountTypesQuery,
  useAddDiscountTypeMutation,
  useUpdateDiscountTypeMutation,
  useUpdateDiscountTypeSortOrderMutation,
  useDeleteDiscountTypeMutation,
  // Apply Discount
  useGetApplyDiscountsQuery,
  useAddApplyDiscountMutation,
  useUpdateApplyDiscountMutation,
  useUpdateApplyDiscountSortOrderMutation,
  useDeleteApplyDiscountMutation,
  // Product Discount
  useGetDiscountsQuery,
  useAddDiscountMutation,
  useUpdateDiscountMutation,
  useUpdateDiscountSortOrderMutation,
  useDeleteDiscountMutation,
  useGetCustomerDiscountInfoMutation
} = productDiscountApi;
