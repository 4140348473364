import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;

export const qualificationApi = createApi({
  reducerPath: "qualificationApi",
  baseQuery: customFetchBase,
  tagTypes: ["Qualification"],
  endpoints: (builder) => ({
    getQualifications: builder.query({
      query: () => apiUrl + "/qualification",
      providesTags: ["Qualification"],
    }),
    addQualification: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/qualification/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Qualification"],
    }),
    updateQualification: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/qualification/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Qualification"],
    }),
    partupdateQualification: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/qualification/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Qualification"],
    }),
    updateQualificationSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Qualification"],
    }),
    deleteQualification: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/qualification/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Qualification"],
    }),
  }),
});

export const {
  useGetQualificationsQuery,
  useAddQualificationMutation,
  useUpdateQualificationMutation,
  useUpdateQualificationSortOrderMutation,
  usePartupdateQualificationMutation,
  useDeleteQualificationMutation,
} = qualificationApi;
