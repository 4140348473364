import { createApi } from '@reduxjs/toolkit/query/react';
import customHeaderFetchBase from '../customHeaderFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.uac;
const multipartHeader = { headers: { 'Content-Type': 'multipart/form-data' } };
const jsonHeader = { headers: { 'Content-Type': 'application/json' } };

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: customHeaderFetchBase,
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: () => ({
        url: apiUrl + '/ecommercecustomer',
        jsonHeader
      })
    }),
    createCustomerAccount: builder.mutation({
      query(formData) {
        return {
          url: apiUrl + '/addecommercecustomer/',
          method: 'POST',
          body: formData,
          multipartHeader
        };
      }
    }),
    approveCustomer: builder.mutation({
      query(formData) {
        return {
          url: apiUrl + '/customerapproval/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    getCustomerLoginInfo: builder.mutation({
      query(formData) {
        return {
          url: apiUrl + '/customerlogininfo/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    updateCustomerAccount: builder.mutation({
      query: (formData) => ({
        url: apiUrl + `/ecommercecustomer/${formData.get('id')}/`,
        method: 'PATCH',
        body: formData,
        multipartHeader
      })
    }),
    deleteCustomerAccount: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/ecommercecustomer/${id}`,
        method: 'DELETE',
        jsonHeader
      })
    })
  })
});

export const {
  useGetCustomersQuery,
  useCreateCustomerAccountMutation,
  useApproveCustomerMutation,
  useUpdateCustomerAccountMutation,
  useDeleteCustomerAccountMutation,
  useGetCustomerLoginInfoMutation
} = customerApi;
