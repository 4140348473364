// import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import AuthGuard from 'auth/AuthGuard';
import DashboardRoutes from 'views/dashboard/DashboardRoutes';
import NotFound from 'views/thiauth/NotFound';
import ThiauthRoutes from 'views/thiauth/ThiauthRoutes';
import TimscontrolRoutes from 'views/timscontrol/timscontrolRoutes';
import HrmRoutes from 'views/hrm/hrmRoutes';
import EcommerceRoutes from 'views/ecommerce/ecommerceRoutes';
import OrgSetupRoutes from 'views/orgsetup/orgsetupRoutes';
import AccPayRoutes from 'views/accpay/accpayRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...DashboardRoutes,
      ...TimscontrolRoutes,
      ...HrmRoutes,
      ...EcommerceRoutes,
      ...OrgSetupRoutes,
      ...AccPayRoutes
    ]
  },
  ...ThiauthRoutes,
  // { path: '/', element: <Navigate to="dashboard" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
