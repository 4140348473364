import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;

export const maritalStatusApi = createApi({
  reducerPath: "maritalStatusApi",
  baseQuery: customFetchBase,
  tagTypes: ["MaritalStatus"],
  endpoints: (builder) => ({
    getMaritalStatuss: builder.query({
      query: () => apiUrl + "/maritalstatus",
      providesTags: ["MaritalStatus"],
    }),
    addMaritalStatus: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/maritalstatus/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["MaritalStatus"],
    }),
    updateMaritalStatus: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/maritalstatus/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["MaritalStatus"],
    }),
    partupdateMaritalStatus: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/maritalstatus/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["MaritalStatus"],
    }),
    updateMaritalStatusSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["MaritalStatus"],
    }),
    deleteMaritalStatus: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/maritalstatus/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MaritalStatus"],
    }),
  }),
});

export const {
  useGetMaritalStatussQuery,
  useAddMaritalStatusMutation,
  useUpdateMaritalStatusMutation,
  useUpdateMaritalStatusSortOrderMutation,
  usePartupdateMaritalStatusMutation,
  useDeleteMaritalStatusMutation,
} = maritalStatusApi;
