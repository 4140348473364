import { lazy } from "react";
import Loadable from "components/Loadable";
// import { authRoles } from '../../auth/authRoles';

const Branch = Loadable(lazy(() => import("./branch")));

const OrgSetupRoutes = [
  {
    path: "/organization-settings/branch",
    element: <Branch />,
    // auth: authRoles.admin,
  },
  {
    path: "/organization-settings/department",
    element: <Branch />,
  },
];

export default OrgSetupRoutes;
