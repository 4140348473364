import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const productDetailApi = createApi({
  reducerPath: "productDetailApi",
  baseQuery: customFetchBase,
  tagTypes: ["ProductDetail"],
  endpoints: (builder) => ({
    getProductDetails: builder.query({
      query: () => apiUrl + "/productdetail",
      providesTags: ["ProductDetail"],
    }),
    addProductDetail: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/productdetail/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ProductDetail"],
    }),
    updateProductDetail: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/productdetail/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductDetail"],
    }),
    updateProductDetailSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductDetail"],
    }),
    deleteProductDetail: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/productdetail/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductDetail"],
    }),
  }),
});

export const {
  useGetProductDetailsQuery,
  useAddProductDetailMutation,
  useUpdateProductDetailMutation,
  useUpdateProductDetailSortOrderMutation,
  useDeleteProductDetailMutation,
} = productDetailApi;
