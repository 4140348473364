import { createApi } from "@reduxjs/toolkit/query/react";
import customHeaderFetchBase from "../customHeaderFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;
// const multipartHeader = { headers: { "Content-Type": "multipart/form-data" } };
const jasonHeader = { headers: { "Content-Type": "application/json" } };

export const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery: customHeaderFetchBase,
  endpoints: (builder) => ({
    approveEmployee: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/manageemployee/",
        method: "POST",
        body: formData,
        jasonHeader,
      }),
    }),
    updateEmployee: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/manageemployee/${id}/`,
        method: "PATCH",
        body: formData,
        jasonHeader,
      }),
    }),
    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/manageemployee/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useApproveEmployeeMutation, useUpdateEmployeeMutation, useDeleteEmployeeMutation } =
  employeeApi;
