import { Box, Breadcrumbs, Grid, Icon, styled, useTheme } from '@mui/material';
// import Hidden from '@mui/material';
import { NavLink } from 'react-router-dom';

const BreadcrumbRoot = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center'
}));

// const BreadcrumbName = styled('h4')(() => ({
//   margin: 0,
//   fontSize: '16px',
//   paddingBottom: '1px',
//   verticalAlign: 'middle',
//   textTransform: 'capitalize'
// }));

// const Separator = styled('h4')(({ theme }) => ({
//   margin: 0,
//   marginLeft: 8,
//   paddingBottom: '3px',
//   color: theme.palette.text.hint
// }));

const SubName = styled('span')(({ theme }) => ({
  // textTransform: 'capitalize',
  color: theme.palette.text.secondary
}));

const StyledIcon = styled(Icon)(() => ({
  marginLeft: 8,
  marginBottom: '4px',
  verticalAlign: 'middle'
}));

const DoubleGridBreadcrumb = ({ routeSegments, actionSegments }) => {
  const theme = useTheme();
  const hint = theme.palette.text.hint;

  return (
    <Grid container spacing={0}>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <BreadcrumbRoot>
          {/* {routeSegments ? (
            <Hidden xsDown>
              <BreadcrumbName>{routeSegments[routeSegments.length - 1]['name']}</BreadcrumbName>
              <Separator>|</Separator>
            </Hidden>
          ) : null} */}

          <Breadcrumbs
            separator={<Icon sx={{ color: hint }}>navigate_next</Icon>}
            sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          >
            <NavLink to="/dashboard">
              <StyledIcon color="primary">home</StyledIcon>
            </NavLink>

            {routeSegments
              ? routeSegments.map((route, index) => {
                  return index !== routeSegments.length - 1 ? (
                    <NavLink key={index} to={route.path}>
                      <SubName>{route.name}</SubName>
                    </NavLink>
                  ) : (
                    <SubName key={index}>{route.name}</SubName>
                  );
                })
              : null}
          </Breadcrumbs>
        </BreadcrumbRoot>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12}>
        {actionSegments ? (
          <Box display="flex" justifyContent="flex-end">
            {actionSegments.includes('create') && (
              <NavLink to="#create">
                <StyledIcon color="primary">add_circle</StyledIcon> Add
              </NavLink>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {actionSegments.includes('list') && (
              <NavLink to="#list">
                <StyledIcon color="primary">list</StyledIcon> List
              </NavLink>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {actionSegments.includes('sort') && (
              <NavLink to="#sort">
                <StyledIcon color="primary">sort_by_alpha</StyledIcon> Sort
              </NavLink>
            )}
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default DoubleGridBreadcrumb;
