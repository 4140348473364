import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const productStockApi = createApi({
  reducerPath: 'productStockApi',
  baseQuery: customFetchBase,
  tagTypes: ['ProductStock', 'StockBatch', 'StockGroup'],
  endpoints: (builder) => ({
    // Product Stock Information
    getProductStocks: builder.query({
      query: () => apiUrl + '/productstock',
      providesTags: ['ProductStock']
    }),
    addProductStock: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/productstock/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['ProductStock']
    }),
    updateProductStock: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/productstock/${id}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['ProductStock']
    }),
    updateStockInfo: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/inventorystockupdate/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['ProductStock']
    }),
    prodStockTransfer: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/productstocktransfer/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['ProductStock']
    }),
    deleteProductStock: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/productstock/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ProductStock']
    }),
    // Stock Batch
    getStockBatchs: builder.query({
      query: () => apiUrl + '/stockbatch',
      providesTags: ['StockBatch']
    }),
    addStockBatch: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/stockbatch/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['StockBatch']
    }),
    updateStockBatch: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/stockbatch/${id}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['StockBatch']
    }),
    updateStockBatchSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + '/sortorder/',
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['StockBatch']
    }),
    deleteStockBatch: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/stockbatch/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['StockBatch']
    }),
    // Stock Group
    manageStockGroup: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/managestockgroup/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['StockGroup']
    }),
    // Copy Stock Data
    copyProdStockData: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/copystockdata/',
        method: 'POST',
        body: formData
      })
    }),
    // Fetch Product Stock History
    getProdStockHistoryByBranchBatchDate: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/prodstockhistorybybranchbatchdate/',
        method: 'POST',
        body: formData
      })
    })
    // next end point
  })
});

export const {
  // Product Stock
  useGetProductStocksQuery,
  useAddProductStockMutation,
  useUpdateProductStockMutation,
  useUpdateStockInfoMutation,
  useProdStockTransferMutation,
  useDeleteProductStockMutation,
  // Stock Batch
  useGetStockBatchsQuery,
  useAddStockBatchMutation,
  useUpdateStockBatchMutation,
  useUpdateStockBatchSortOrderMutation,
  useDeleteStockBatchMutation,
  // Stock Group
  useManageStockGroupMutation,
  // Copy Stock Data
  useCopyProdStockDataMutation,
  // Fetch Product Stock History
  useGetProdStockHistoryByBranchBatchDateMutation
} = productStockApi;
