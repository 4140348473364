import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.tims;

export const pwdRecoveryOptionApi = createApi({
  reducerPath: 'pwdRecoveryOptionApi',
  baseQuery: customFetchBase,
  tagTypes: ['PwdRecoveryOption'],
  endpoints: (builder) => ({
    getPwdRecoveryOptions: builder.query({
      query: () => apiUrl + '/pwdrecoveryoption',
      providesTags: ['PwdRecoveryOption']
    }),
    addPwdRecoveryOption: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/pwdrecoveryoption/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['PwdRecoveryOption']
    }),
    updatePwdRecoveryOption: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/pwdrecoveryoption/${id}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['PwdRecoveryOption']
    }),
    updatePwdRecoveryOptionSortOrder: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/sortorder/',
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['PwdRecoveryOption']
    }),
    deletePwdRecoveryOption: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/pwdrecoveryoption/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['PwdRecoveryOption']
    })
  })
});

export const {
  useGetPwdRecoveryOptionsQuery,
  useAddPwdRecoveryOptionMutation,
  useUpdatePwdRecoveryOptionMutation,
  useUpdatePwdRecoveryOptionSortOrderMutation,
  useDeletePwdRecoveryOptionMutation
} = pwdRecoveryOptionApi;
