import { lazy } from 'react';
import Loadable from 'components/Loadable';
// import { authRoles } from '../../auth/authRoles';

const Branch = Loadable(lazy(() => import('./branch')));
const PaymentMethod = Loadable(lazy(() => import('./paymentmethod')));

const AccPayRoutes = [
  {
    path: '/account-payments/payment-method',
    element: <PaymentMethod />
    // auth: authRoles.admin,
  },
  {
    path: '/account-payments/bank',
    element: <Branch />
  }
];

export default AccPayRoutes;
