import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.uac;

export const userCategoryApi = createApi({
  reducerPath: "userCategoryApi",
  baseQuery: customFetchBase,
  tagTypes: ["UserCategory"],
  endpoints: (builder) => ({
    getUserCategories: builder.query({
      query: () => apiUrl + "/usercategory",
      providesTags: ["UserCategory"],
    }),
    addUserCategory: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/usercategory/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["UserCategory"],
    }),
    updateUserCategory: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/usercategory/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["UserCategory"],
    }),
    updateUserCategorySortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["UserCategory"],
    }),
    deleteUserCategory: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/usercategory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserCategory"],
    }),
  }),
});

export const {
  useGetUserCategoriesQuery,
  useAddUserCategoryMutation,
  useUpdateUserCategoryMutation,
  useUpdateUserCategorySortOrderMutation,
  useDeleteUserCategoryMutation,
} = userCategoryApi;
