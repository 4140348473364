import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.tims;

export const contactUsApi = createApi({
  reducerPath: 'contactUsApi',
  baseQuery: customFetchBase,
  tagTypes: ['ContactUs'],
  endpoints: (builder) => ({
    getContactusMessages: builder.query({
      query: () => apiUrl + '/contactus',
      providesTags: ['ContactUs']
    }),
    addContactusMessage: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/contactus/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['ContactUs']
    }),
    updateContactusMessage: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/contactus/${id}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['ContactUs']
    }),
    deleteContactusMessage: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/contactus/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ContactUs']
    })
  })
});

export const {
  useGetContactusMessagesQuery,
  useAddContactusMessageMutation,
  useUpdateContactusMessageMutation,
  useDeleteContactusMessageMutation
} = contactUsApi;
