import { Navigate, useLocation } from 'react-router-dom';
import appConfig from 'appconfig';
import useAuth from 'hooks/useAuth';

const userHasPermission = (pathname, userTasks) => {
  if (!pathname || !userTasks || !Array.isArray(userTasks) || userTasks.length < 1) {
    return false;
  }
  const matched = userTasks.find((task) => task.taskUrl === pathname);

  return matched !== undefined;
};

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  if (isAuthenticated) {
    // console.log('pathname: ', pathname);
    if (
      appConfig.PERMITTED_ROUTES.includes(pathname) ||
      userHasPermission(pathname, user.roleTasks)
    ) {
      return <>{children}</>;
    } else {
      return <Navigate to="/tims-control/security-check" />;
    }
  }
  return <Navigate replace to="/account/sign-in" state={{ from: pathname }} />;
};

export default AuthGuard;
