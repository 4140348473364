import { createApi } from "@reduxjs/toolkit/query/react";
import fileUploadFetchBase from "../customHeaderFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;
const configHeaders = { headers: { "Content-Type": "application/json" } };
const configHeadersFileUpload = {
  headers: { "Content-Type": "multipart/form-data" },
};

export const productInventoryApi = createApi({
  reducerPath: "productInventoryApi",
  baseQuery: fileUploadFetchBase,
  tagTypes: ["ProductInventory"],
  endpoints: (builder) => ({
    getProductInventories: builder.query({
      query: () => ({ url: apiUrl + "/productinventory", configHeaders }),
      providesTags: ["ProductInventory"],
    }),
    addProductInventory: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/productinventory/",
        method: "POST",
        body: formData,
        configHeadersFileUpload,
      }),
      invalidatesTags: ["ProductInventory"],
    }),
    updateProductInventory: builder.mutation({
      query: (formData) => ({
        url: apiUrl + `/productinventory/${formData.get("id")}/`,
        method: "PATCH",
        body: formData,
        configHeadersFileUpload,
      }),
      invalidatesTags: ["ProductInventory"],
    }),
    patchProductInventory: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/productinventory/${id}/`,
        method: "PATCH",
        body: formData,
        configHeadersFileUpload,
      }),
      invalidatesTags: ["ProductInventory"],
    }),
    deleteProductInventory: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/productinventory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductInventory"],
    }),
  }),
});

export const {
  useGetProductInventoriesQuery,
  useAddProductInventoryMutation,
  useUpdateProductInventoryMutation,
  usePatchProductInventoryMutation,
  useDeleteProductInventoryMutation,
} = productInventoryApi;
