import { createApi } from '@reduxjs/toolkit/query/react';
import customHeaderFetchBase from '../customHeaderFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.pay;
const multipartHeader = { headers: { 'Content-Type': 'multipart/form-data' } };
const jsonHeader = { headers: { 'Content-Type': 'application/json' } };

export const paymentMethodApi = createApi({
  reducerPath: 'paymentMethodApi',
  baseQuery: customHeaderFetchBase,
  tagTypes: ['PaymentMethod'],
  endpoints: (builder) => ({
    getPaymentMethods: builder.query({
      query: () => ({
        url: apiUrl + '/paymethod',
        jsonHeader
      }),
      providesTags: ['PaymentMethod']
    }),
    addPaymentMethod: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/paymethod/',
        method: 'POST',
        body: formData,
        multipartHeader
      }),
      invalidatesTags: ['PaymentMethod']
    }),
    updatePaymentMethod: builder.mutation({
      query: (formData) => ({
        url: apiUrl + `/paymethod/${formData.get('id')}/`,
        method: 'PATCH',
        body: formData,
        multipartHeader
      }),
      invalidatesTags: ['PaymentMethod']
    }),
    updatePaymentMethodSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + '/sortorder/',
        method: 'PATCH',
        body: formData,
        jsonHeader
      }),
      invalidatesTags: ['PaymentMethod']
    }),
    deletePaymentMethod: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/paymethod/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['PaymentMethod']
    })
  })
});

export const {
  useGetPaymentMethodsQuery,
  useAddPaymentMethodMutation,
  useUpdatePaymentMethodMutation,
  useUpdatePaymentMethodSortOrderMutation,
  useDeletePaymentMethodMutation
} = paymentMethodApi;
