import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const productBrandApi = createApi({
  reducerPath: "productBrandApi",
  baseQuery: customFetchBase,
  tagTypes: ["ProductBrand"],
  endpoints: (builder) => ({
    getProductBrands: builder.query({
      query: () => apiUrl + "/productbrand",
      providesTags: ["ProductBrand"],
    }),
    addProductBrand: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/productbrand/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ProductBrand"],
    }),
    updateProductBrand: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/productbrand/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductBrand"],
    }),
    updateProductBrandSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductBrand"],
    }),
    deleteProductBrand: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/productbrand/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductBrand"],
    }),
  }),
});

export const {
  useGetProductBrandsQuery,
  useAddProductBrandMutation,
  useUpdateProductBrandMutation,
  useUpdateProductBrandSortOrderMutation,
  useDeleteProductBrandMutation,
} = productBrandApi;
