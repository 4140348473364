/*
Document   : dashboard/DashboardRoutes
Created on : Sat 05 Aug 2023 18∶23∶47
Author     : Manasseh Achirka
 */

import Loadable from 'components/Loadable';
import { lazy } from 'react';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const DashboardRoutes = [
  {
    path: '/dashboard',
    element: <Analytics />
  }
];

export default DashboardRoutes;
