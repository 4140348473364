import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.org;

export const branchApi = createApi({
  reducerPath: 'branchApi',
  baseQuery: customFetchBase,
  tagTypes: ['Branch'],
  endpoints: (builder) => ({
    getBranches: builder.query({
      query: () => apiUrl + '/branch',
      providesTags: ['Branch']
    }),
    addBranch: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/branch/',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['Branch']
    }),
    updateBranch: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/branch/${id}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['Branch']
    }),
    updateBranchSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + '/sortorder/',
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['Branch']
    }),
    deleteBranch: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/branch/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Branch']
    })
  })
});

export const {
  useGetBranchesQuery,
  useAddBranchMutation,
  useUpdateBranchMutation,
  useUpdateBranchSortOrderMutation,
  useDeleteBranchMutation
} = branchApi;
