import { createApi } from '@reduxjs/toolkit/query/react';
import customHeaderFetchBase from '../customHeaderFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;
const multipartHeader = { headers: { 'Content-Type': 'multipart/form-data' } };
const jsonHeader = { headers: { 'Content-Type': 'application/json' } };

export const downloadUploadProdStockApi = createApi({
  reducerPath: 'downloadUploadProdStockApi',
  baseQuery: customHeaderFetchBase,
  endpoints: (builder) => ({
    // Download Product Stock Template
    generateProdStockTemplate: builder.mutation({
      query(formData) {
        return {
          url: apiUrl + '/generateprodstktemplate/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    // Upload Product Stock Info
    uploadProdStockInfo: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/uploadprodstkinfoexcel/',
        method: 'POST',
        body: formData,
        multipartHeader
      })
    })
  })
});

export const { useGenerateProdStockTemplateMutation, useUploadProdStockInfoMutation } =
  downloadUploadProdStockApi;
