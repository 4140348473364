import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;


export const empDocApi = createApi({
  reducerPath: "empDocApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    // Personal Information
    addEmpPersonalInfo: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/empapplication/",
        method: "POST",
        body: formData,
      }),
    }),
    updateEmpPersonalInfo: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/empapplication/${id}/`,
        method: "PATCH",
        body: formData,
      }),
    }),
    // EmpQualification
    addEmpQualification: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/empqualification/",
        method: "POST",
        body: formData,
      }),
    }),
    deleteEmpQualification: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/empqualification/${id}`,
        method: "DELETE",
      }),
    }),
    // EmpCertitification
    addEmpCertification: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/empcertification/",
        method: "POST",
        body: formData,
      }),
    }),
    deleteEmpCertification: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/empcertification/${id}`,
        method: "DELETE",
      }),
    }),
    // EmpNextOfKin
    addEmpNextOfKin: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/empnextofkin/",
        method: "POST",
        body: formData,
      }),
    }),
    updateEmpNextOfKin: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/empnextofkin/${id}/`,
        method: "PUT",
        body: formData,
      }),
    }),
    // EmpReferee
    addEmpReferee: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/empreferee/",
        method: "POST",
        body: formData,
      }),
    }),
    updateEmpReferee: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/empreferee/${id}/`,
        method: "PUT",
        body: formData,
      }),
    }),
    deleteEmpReferee: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/empreferee/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddEmpPersonalInfoMutation,
  useUpdateEmpPersonalInfoMutation,
  useAddEmpQualificationMutation,
  useDeleteEmpQualificationMutation,
  useAddEmpCertificationMutation,
  useDeleteEmpCertificationMutation,
  useAddEmpNextOfKinMutation,
  useUpdateEmpNextOfKinMutation,
  useAddEmpRefereeMutation,
  useUpdateEmpRefereeMutation,
  useDeleteEmpRefereeMutation,
} = empDocApi;
