import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;

export const occupationApi = createApi({
  reducerPath: "occupationApi",
  baseQuery: customFetchBase,
  tagTypes: ["Occupation"],
  endpoints: (builder) => ({
    getOccupations: builder.query({
      query: () => apiUrl + "/occupation",
      providesTags: ["Occupation"],
    }),
    addOccupation: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/occupation/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Occupation"],
    }),
    updateOccupation: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/occupation/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Occupation"],
    }),
    partupdateOccupation: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/occupation/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Occupation"],
    }),
    updateOccupationSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Occupation"],
    }),
    deleteOccupation: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/occupation/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Occupation"],
    }),
  }),
});

export const {
  useGetOccupationsQuery,
  useAddOccupationMutation,
  useUpdateOccupationMutation,
  useUpdateOccupationSortOrderMutation,
  usePartupdateOccupationMutation,
  useDeleteOccupationMutation,
} = occupationApi;
