import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const productAttributeValueApi = createApi({
  reducerPath: "productAttributeValueApi",
  baseQuery: customFetchBase,
  tagTypes: ["ProductAttributeValue"],
  endpoints: (builder) => ({
    getProductAttributeValues: builder.query({
      query: () => apiUrl + "/productattributevalue",
      providesTags: ["ProductAttributeValue"],
    }),
    addProductAttributeValue: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/productattributevalue/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ProductAttributeValue"],
    }),
    updateProductAttributeValue: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/productattributevalue/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductAttributeValue"],
    }),
    updateProductAttributeValueSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductAttributeValue"],
    }),
    deleteProductAttributeValue: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/productattributevalue/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductAttributeValue"],
    }),
  }),
});

export const {
  useGetProductAttributeValuesQuery,
  useAddProductAttributeValueMutation,
  useUpdateProductAttributeValueMutation,
  useUpdateProductAttributeValueSortOrderMutation,
  useDeleteProductAttributeValueMutation,
} = productAttributeValueApi;
