import React, { createContext, useEffect, useReducer } from 'react';
import { MatxLoading } from 'components';
import { encryptData, decryptData, isValidToken } from 'views/commons/commonhooks';

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const setSession = (responseData) => {
  if (Object.keys(responseData).length > 0) {
    localStorage.setItem('B397E242C342DE6BCA', encryptData(responseData.access_token));
    localStorage.setItem('C5B13553857667A5ER', encryptData(responseData.refresh_token));
    localStorage.setItem('US878FE7B934FE1', encryptData(responseData.user));
  } else {
    localStorage.removeItem('B397E242C342DE6BCA');
    localStorage.removeItem('C5B13553857667A5ER');
    localStorage.removeItem('US878FE7B934FE1');
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isInitialised: true, isAuthenticated, user };
    }
    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'THI',
  login: () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const login = (responseData) => {
    const user = {};
    Object.assign(user, responseData);
    //remove access & refresh tokens from user object bofore updating state/store
    delete user.access;
    delete user.refresh;
    setSession({
      access_token: responseData.access,
      refresh_token: responseData.refresh,
      user: user
    });
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession({});
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem('C5B13553857667A5ER')
      ? decryptData(localStorage.getItem('C5B13553857667A5ER'))
      : null;
    if (isValidToken(refreshToken)) {
      const user = localStorage.getItem('US878FE7B934FE1')
        ? decryptData(localStorage.getItem('US878FE7B934FE1'))
        : null;

      dispatch({
        type: 'INIT',
        payload: {
          isAuthenticated: true,
          user
        }
      });
    } else {
      dispatch({
        type: 'INIT',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'THI',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
