import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.tims;

export const appMenuApi = createApi({
  reducerPath: "appMenuApi",
  baseQuery: customFetchBase,
  tagTypes: ["AppMenu"],
  endpoints: (builder) => ({
    getAppMenus: builder.query({
      query: () => apiUrl + "/appmenu",
      providesTags: ["AppMenu"],
    }),
    addAppMenu: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/appmenu/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AppMenu"],
    }),
    updateAppMenu: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/appmenu/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["AppMenu"],
    }),
    partupdateAppMenu: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/appmenu/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["AppMenu"],
    }),
    updateAppMenuSortOrder: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["AppMenu"],
    }),
    deleteAppMenu: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/appmenu/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AppMenu"],
    }),
  }),
});

export const {
  useGetAppMenusQuery,
  useAddAppMenuMutation,
  useUpdateAppMenuMutation,
  useUpdateAppMenuSortOrderMutation,
  usePartupdateAppMenuMutation,
  useDeleteAppMenuMutation,
} = appMenuApi;
