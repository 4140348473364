import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;

export const religionApi = createApi({
  reducerPath: "religionApi",
  baseQuery: customFetchBase,
  tagTypes: ["Religion"],
  endpoints: (builder) => ({
    getReligions: builder.query({
      query: () => apiUrl + "/religion",
      providesTags: ["Religion"],
    }),
    addReligion: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/religion/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Religion"],
    }),
    updateReligion: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/religion/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Religion"],
    }),
    partupdateReligion: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/religion/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Religion"],
    }),
    updateReligionSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Religion"],
    }),
    deleteReligion: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/religion/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Religion"],
    }),
  }),
});

export const {
  useGetReligionsQuery,
  useAddReligionMutation,
  useUpdateReligionMutation,
  useUpdateReligionSortOrderMutation,
  usePartupdateReligionMutation,
  useDeleteReligionMutation,
} = religionApi;
