import { lazy } from 'react';
import Loadable from 'components/Loadable';
// import { authRoles } from '../../auth/authRoles';

const PersonalTitle = Loadable(lazy(() => import('./personaltitle')));
const MaritalStatus = Loadable(lazy(() => import('./maritalstatus')));
const Religion = Loadable(lazy(() => import('./religion')));
const Relationship = Loadable(lazy(() => import('./relationship')));
const Occupation = Loadable(lazy(() => import('./occupation')));
const StaffDocumentation = Loadable(lazy(() => import('./staffdocumentation')));
const StaffDocumentationList = Loadable(lazy(() => import('./list/staffdoc-listdel')));
const EmpApproval = Loadable(lazy(() => import('./create/empapproval-createupdate')));
const ApprovedStaffDocumentationList = Loadable(
  lazy(() => import('./list/approvedstaffdoc-listdel'))
);
const EmpActivation = Loadable(lazy(() => import('./create/empactivation-createupdate')));
const Qualification = Loadable(lazy(() => import('./qualification')));
const UploadDocType = Loadable(lazy(() => import('./uploaddoctype')));
const StaffList = Loadable(lazy(() => import('./list/staff-listdel')));
const ManageStaffInformation = Loadable(lazy(() => import('./create/manage-staff-info')));
const ContactUsMessagesList = Loadable(lazy(() => import('./list/contact-us-messages-listdel')));

const HrmRoutes = [
  {
    path: '/human-resources/personal-title',
    element: <PersonalTitle />
    // auth: authRoles.admin,
  },
  { path: '/human-resources/marital-status', element: <MaritalStatus /> },
  { path: '/human-resources/religion', element: <Religion /> },
  { path: '/human-resources/relationship', element: <Relationship /> },
  { path: '/human-resources/occupation', element: <Occupation /> },
  { path: '/human-resources/staff-documentation', element: <StaffDocumentation /> },
  { path: '/human-resources/staff-doc-list', element: <StaffDocumentationList /> },
  { path: '/human-resources/approve-staff', element: <EmpApproval /> },
  { path: '/human-resources/approved-staffdoc-list', element: <ApprovedStaffDocumentationList /> },
  { path: '/human-resources/employee-activation', element: <EmpActivation /> },
  { path: '/human-resources/user-qualification', element: <Qualification /> },
  { path: '/human-resources/documents-to-upload', element: <UploadDocType /> },
  { path: '/human-resources/staff-listing', element: <StaffList /> },
  { path: '/human-resources/update-emp-info', element: <ManageStaffInformation /> },
  { path: '/human-resources/contact-us-messages', element: <ContactUsMessagesList /> }
];

export default HrmRoutes;
