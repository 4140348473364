import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: customFetchBase,
  // tagTypes: ['Order'],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => apiUrl + '/order'
      // providesTags: ['Order']
    }),
    getOrdersByBranchAndDate: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/ordersbybranchanddate/',
        method: 'POST',
        body: formData
      })
      // invalidatesTags: ['Order']
    }),
    placeOrder: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/order/',
        method: 'POST',
        body: formData
      })
      // invalidatesTags: ['Order']
    }),
    approveOrder: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/approveorder/',
        method: 'POST',
        body: formData
      })
    }),
    cancelOrder: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/cancelorder/',
        method: 'POST',
        body: formData
      })
    }),
    updateOrder: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/order/${id}/`,
        method: 'PATCH',
        body: formData
      })
      // invalidatesTags: ['Order']
    }),
    updateOrderPayment: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/updateorderpayment/',
        method: 'POST',
        body: formData
      })
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/order/${id}`,
        method: 'DELETE'
      })
      // invalidatesTags: ['Order']
    })
  })
});

export const {
  useGetOrdersQuery,
  useGetOrdersByBranchAndDateMutation,
  usePlaceOrderMutation,
  useApproveOrderMutation,
  useCancelOrderMutation,
  useUpdateOrderMutation,
  useUpdateOrderPaymentMutation,
  useDeleteOrderMutation
} = orderApi;
