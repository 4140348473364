import { createApi } from '@reduxjs/toolkit/query/react';
import customHeaderFetchBase from '../customHeaderFetchBase';
import appConfig from 'appconfig';

// const multipartHeader = { headers: { 'Content-Type': 'multipart/form-data' } };
const jsonHeader = { headers: { 'Content-Type': 'application/json' } };

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customHeaderFetchBase,
  endpoints: (build) => ({
    loginUser: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiAuthApiBaseUrl + 'token/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    getSecretQuestions: build.query({
      query: () => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/secretquestion',
        jsonHeader
      })
    }),
    firstTimeLogin: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/firsttimelogin/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    logoutUserBackend: build.mutation({
      query(userId) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/logout/',
          method: 'POST',
          body: { userId: userId },
          jsonHeader
        };
      }
    }),
    changePassword: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/changeuserpassword/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    createNewPassword: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/createnewpassword/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    verifyUserByEmail: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/verifyuserbyemail/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    getPwdRecoveryOpts: build.query({
      query: () => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + '/pwdrecoveryopts',
        jsonHeader
      })
    }),
    verifyUserQuestionAndAnswer: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/verifyuserqanda/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    sendPasswordResetLinkToUser: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/sendpwdresetlinktouseremail/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    verifyPasswordResetLink: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/verifypwdresetlink/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    sendPasswordResetCodeToUser: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/sendpwdresetcodetouserphone/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    verifyPasswordResetCode: build.mutation({
      query(formData) {
        return {
          url: appConfig.thiapiBaseUrl + appConfig.modules.uac + '/verifypwdresetcode/',
          method: 'POST',
          body: formData,
          jsonHeader
        };
      }
    }),
    getuserptitles: build.query({
      query: () => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.hr + '/userptitles',
        jsonHeader
      })
    })
    // logoutUser: build.mutation({
    //   query(refresh_token) {
    //     return {
    //       url: appConfig.thiAuthApiBaseUrl + "logout/blacklist//",
    //       method: "POST",
    //       body: { refresh_token: refresh_token },
    //     };
    //   },
    //   async onQueryStarted(args, { dispatch, queryFulfilled }) {
    //     try {
    //       await queryFulfilled;
    //       dispatch(logoutUser());
    //     } catch (error) {}
    //   },
    // }),
  })
});

export const {
  useLoginUserMutation,
  useGetSecretQuestionsQuery,
  useFirstTimeLoginMutation,
  useLogoutUserBackendMutation,
  useChangePasswordMutation,
  useCreateNewPasswordMutation,
  useVerifyUserByEmailMutation,
  useGetPwdRecoveryOptsQuery,
  useVerifyUserQuestionAndAnswerMutation,
  useSendPasswordResetLinkToUserMutation,
  useVerifyPasswordResetLinkMutation,
  useSendPasswordResetCodeToUserMutation,
  useVerifyPasswordResetCodeMutation,
  useGetuserptitlesQuery
} = authApi;
