import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.tims;

export const appRoleApi = createApi({
  reducerPath: "appRoleApi",
  baseQuery: customFetchBase,
  tagTypes: ["AppRole"],
  endpoints: (builder) => ({
    getAppRoles: builder.query({
      query: () => apiUrl + "/approle",
      providesTags: ["AppRole"],
    }),
    addAppRole: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/approle/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AppRole"],
    }),
    updateAppRole: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/approle/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["AppRole"],
    }),
    partupdateAppRole: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/approle/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["AppRole"],
    }),
    updateAppRoleSortOrder: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["AppRole"],
    }),
    deleteAppRole: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/approle/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AppRole"],
    }),
  }),
});

export const {
  useGetAppRolesQuery,
  useAddAppRoleMutation,
  useUpdateAppRoleMutation,
  useUpdateAppRoleSortOrderMutation,
  usePartupdateAppRoleMutation,
  useDeleteAppRoleMutation,
} = appRoleApi;
