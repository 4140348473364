import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.tims;

export const commonSelectorsApi = createApi({
  reducerPath: "commonSelectorsApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getDataForSelect: builder.query({
      query: (queryData) => ({
        url: apiUrl + "/cmonsetor/",
        method: "GET",
        params: queryData,
      }),
    }),
    fetchDataForSelect: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/cmonsetor/",
        method: "POST",
        body: formData,
      }),
    }),
    getDataForSelectRQ: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/cmonsetorrqrq/",
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const { useGetDataForSelectQuery, useFetchDataForSelectMutation, useGetDataForSelectRQMutation } =
  commonSelectorsApi;
