import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const productCategoryApi = createApi({
  reducerPath: "productCategoryApi",
  baseQuery: customFetchBase,
  tagTypes: ["ProductCategory"],
  endpoints: (builder) => ({
    getProductCategories: builder.query({
      query: () => apiUrl + "/productcategory",
      providesTags: ["ProductCategory"],
    }),
    addProductCategory: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/productcategory/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ProductCategory"],
    }),
    updateProductCategory: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/productcategory/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductCategory"],
    }),
    updateProductCategorySortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductCategory"],
    }),
    deleteProductCategory: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/productcategory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductCategory"],
    }),
  }),
});

export const {
  useGetProductCategoriesQuery,
  useAddProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useUpdateProductCategorySortOrderMutation,
  useDeleteProductCategoryMutation,
} = productCategoryApi;
