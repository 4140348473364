import { lazy } from 'react';
import Loadable from 'components/Loadable';
// import { authRoles } from '../../auth/authRoles';

const AppModule = Loadable(lazy(() => import('./appmodule')));
const AppTask = Loadable(lazy(() => import('./apptask')));
const AppMenu = Loadable(lazy(() => import('./appmenu')));
const AppRole = Loadable(lazy(() => import('./approle')));
const AppMenuTask = Loadable(lazy(() => import('./appmenutask')));
const AppRoleMenu = Loadable(lazy(() => import('./approlemenu')));
const UserCategory = Loadable(lazy(() => import('./usercategory')));
const AccessDenied = Loadable(lazy(() => import('./accessdenied')));
const AppUserRole = Loadable(lazy(() => import('./appuserrole')));
const UserProfile = Loadable(lazy(() => import('./userprofile')));
const ChangePassword = Loadable(lazy(() => import('./changepassword')));
const PwdRecoveryOption = Loadable(lazy(() => import('./pwdrecoveryoption')));

const TimscontrolRoutes = [
  {
    path: '/tims-control/app-module',
    element: <AppModule />
    // auth: authRoles.admin,
  },
  {
    path: '/tims-control/app-task',
    element: <AppTask />
  },
  {
    path: '/tims-control/app-menu',
    element: <AppMenu />
  },
  {
    path: '/tims-control/app-role',
    element: <AppRole />
  },
  {
    path: '/tims-control/bind-task-to-menu',
    element: <AppMenuTask />
  },
  {
    path: '/tims-control/bind-menu-to-role',
    element: <AppRoleMenu />
  },
  {
    path: '/tims-control/user-category',
    element: <UserCategory />
  },
  {
    path: '/tims-control/security-check',
    element: <AccessDenied />
  },
  {
    path: '/tims-control/bind-role-to-user',
    element: <AppUserRole />
  },
  {
    path: '/self-service/user-profile',
    element: <UserProfile />
  },
  {
    path: '/self-service/change-password',
    element: <ChangePassword />
  },
  {
    path: '/tims-control/password-recovery-option',
    element: <PwdRecoveryOption />
  }
];

export default TimscontrolRoutes;
