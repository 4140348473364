import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const productAttributeApi = createApi({
  reducerPath: "productAttributeApi",
  baseQuery: customFetchBase,
  tagTypes: ["ProductAttribute"],
  endpoints: (builder) => ({
    getProductAttributes: builder.query({
      query: () => apiUrl + "/productattribute",
      providesTags: ["ProductAttribute"],
    }),
    addProductAttribute: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/productattribute/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ProductAttribute"],
    }),
    updateProductAttribute: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/productattribute/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductAttribute"],
    }),
    updateProductAttributeSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductAttribute"],
    }),
    deleteProductAttribute: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/productattribute/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductAttribute"],
    }),
  }),
});

export const {
  useGetProductAttributesQuery,
  useAddProductAttributeMutation,
  useUpdateProductAttributeMutation,
  useUpdateProductAttributeSortOrderMutation,
  useDeleteProductAttributeMutation,
} = productAttributeApi;
