import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { changeUserAccessToken, logoutUser } from 'redox/features/thiauth/authSlice';
import { decryptData, isValidToken } from 'views/commons/commonhooks';
import { Mutex } from 'async-mutex';
import appConfig from 'appconfig';

// create a new mutex
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: appConfig.thiAuthApiBaseUrl,
  prepareHeaders: (headers) => {
    headers.set(
      'Authorization',
      localStorage.getItem('B397E242C342DE6BCA')
        ? 'Bearer ' + decryptData(localStorage.getItem('B397E242C342DE6BCA'))
        : null
    );
    headers.set('accept', 'application/json');
    return headers;
  }
});

const customHeaderFetchBase = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  //check if connection to backend/api server was successful
  if (result.error && result.error.status === 'FETCH_ERROR') {
    return alert(
      'An unexpected internal server error error occurred.\n' +
        'The server is currently unavailable due to failure during initialization of services.\n' +
        'Sorry about this - we will get it fixed shortly.'
    );
  }
  //check for auth credentials
  if (
    result.error &&
    result.error.status === 401 &&
    result.error.data.detail === 'Authentication credentials were not provided.'
  ) {
    api.dispatch(logoutUser());
    window.alert('It appears you do not have a valid session please login to continue.');
    window.location.href = '/account/sign-in?nextmove=' + window.location.pathname;
  }
  //check for possible 401 unauthorizd error probably due expired refresh or access tokens
  if (result.error && result.error.status === 401 && result.error.data.code === 'token_not_valid') {
    const refreshToken = localStorage.getItem('C5B13553857667A5ER')
      ? decryptData(localStorage.getItem('C5B13553857667A5ER'))
      : null;
    if (isValidToken(refreshToken)) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await baseQuery(
            {
              method: 'POST',
              url: 'token/refresh/',
              body: { refresh: refreshToken }
            },
            api,
            extraOptions
          );

          if (refreshResult.data) {
            api.dispatch(changeUserAccessToken(refreshResult.data));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(logoutUser());
            window.alert(
              'It appears your session has expired and you will be required to sign in again.'
            );
            window.location.href = '/account/sign-in?nextmove=' + window.location.pathname;
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      api.dispatch(logoutUser());
      window.alert(
        'It appears your session has expired and you will be required to sign in again.'
      );
      window.location.href = '/account/sign-in?nextmove=' + window.location.pathname;
    }
  }
  return result;
};

export default customHeaderFetchBase;
