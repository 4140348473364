import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.tims;

export const appModuleApi = createApi({
  reducerPath: "appModuleApi",
  baseQuery: customFetchBase,
  tagTypes: ["AppModule"],
  endpoints: (builder) => ({
    getAppModules: builder.query({
      query: () => apiUrl + "/appmodule",
      providesTags: ["AppModule"],
    }),
    addAppModule: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/appmodule/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["AppModule"],
    }),
    updateAppModule: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/appmodule/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["AppModule"],
    }),
    partupdateAppModule: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/appmodule/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["AppModule"],
    }),
    updateAppModuleSortOrder: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["AppModule"],
    }),
    deleteAppModule: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/appmodule/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AppModule"],
    }),
  }),
});

export const {
  useGetAppModulesQuery,
  useAddAppModuleMutation,
  useUpdateAppModuleMutation,
  useUpdateAppModuleSortOrderMutation,
  usePartupdateAppModuleMutation,
  useDeleteAppModuleMutation,
} = appModuleApi;
