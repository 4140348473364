import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.ecomm;

export const productTypeApi = createApi({
  reducerPath: "productTypeApi",
  baseQuery: customFetchBase,
  tagTypes: ["ProductType"],
  endpoints: (builder) => ({
    getProductTypes: builder.query({
      query: () => apiUrl + "/producttype",
      providesTags: ["ProductType"],
    }),
    addProductType: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/producttype/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ProductType"],
    }),
    updateProductType: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/producttype/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductType"],
    }),
    updateProductTypeSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ProductType"],
    }),
    deleteProductType: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/producttype/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductType"],
    }),
  }),
});

export const {
  useGetProductTypesQuery,
  useAddProductTypeMutation,
  useUpdateProductTypeMutation,
  useUpdateProductTypeSortOrderMutation,
  useDeleteProductTypeMutation,
} = productTypeApi;
