import { createSlice } from "@reduxjs/toolkit";
import { encryptData, decryptData } from "views/commons/commonhooks";

// const userInfoFromStorage = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
const userInfoFromStorage = localStorage.getItem("US878FE7B934FE1") ? decryptData(localStorage.getItem("US878FE7B934FE1")) : {};

const initialState = {
  // initialize state from local storage to enable user to stay logged in
  user: userInfoFromStorage,
};

export const authSlice = createSlice({
  initialState,
  name: "authSlice",
  reducers: {
    addLoggedInUserInfoToStore: (state, action) => {
      let userInfo = {};
      const responseData = action.payload;
      Object.assign(userInfo, responseData);
      //remove access & refresh tokens from userInfo object bofore updating state/store
      delete userInfo.access;
      delete userInfo.refresh;
      Object.assign(state.user, userInfo);
    },
    changeUserAccessToken: (state, action) => {
      const responseData = action.payload;
      localStorage.setItem("B397E242C342DE6BCA", encryptData(responseData.access));
    },
    logoutUser: (state, action) => {
      Object.assign(state, initialState);
    },
  },
  // extraReducers:
});

export default authSlice.reducer;

export const { addLoggedInUserInfoToStore, changeUserAccessToken, logoutUser } = authSlice.actions;
