import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import appConfig from 'appconfig';

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.tims;
const uacApiUrl = appConfig.thiapiBaseUrl + appConfig.modules.uac;

export const timsControlCustomApi = createApi({
  reducerPath: 'timsControlCustomApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMenuTask: builder.query({
      query: () => ({
        query: () => apiUrl + '/appmenutask'
      })
    }),
    bindTaskToMenu: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/appmenutask/',
        method: 'POST',
        body: formData
      })
    }),
    bindMenuToRole: builder.mutation({
      query: (formData) => ({
        url: apiUrl + '/approlemenu/',
        method: 'POST',
        body: formData
      })
    }),
    getLoginUsersByCategory: builder.mutation({
      query: (formData) => ({
        url: uacApiUrl + '/loginusersbycategory/',
        method: 'POST',
        body: formData
      })
    }),
    bindRoleToUser: builder.mutation({
      query: (formData) => ({
        url: uacApiUrl + '/appuserrole/',
        method: 'POST',
        body: formData
      })
    })
  })
});

export const {
  useGetMenuTaskQuery,
  useBindTaskToMenuMutation,
  useBindMenuToRoleMutation,
  useGetLoginUsersByCategoryMutation,
  useBindRoleToUserMutation
} = timsControlCustomApi;
