import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;

export const relationshipApi = createApi({
  reducerPath: "relationshipApi",
  baseQuery: customFetchBase,
  tagTypes: ["Relationship"],
  endpoints: (builder) => ({
    getRelationships: builder.query({
      query: () => apiUrl + "/relationship",
      providesTags: ["Relationship"],
    }),
    addRelationship: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/relationship/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Relationship"],
    }),
    updateRelationship: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/relationship/${id}/`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Relationship"],
    }),
    partupdateRelationship: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/relationship/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Relationship"],
    }),
    updateRelationshipSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Relationship"],
    }),
    deleteRelationship: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/relationship/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Relationship"],
    }),
  }),
});

export const {
  useGetRelationshipsQuery,
  useAddRelationshipMutation,
  useUpdateRelationshipMutation,
  useUpdateRelationshipSortOrderMutation,
  usePartupdateRelationshipMutation,
  useDeleteRelationshipMutation,
} = relationshipApi;
