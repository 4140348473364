/*
Document   : redox/appstore
Created on : Sat 05 Aug 2023 17∶34∶43
Author     : Manasseh Achirka
 */

import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './api/thiauth/authApi';
import { customerApi } from './api/thiauth/customerApi';
import authReducer from './features/thiauth/authSlice';
import EcommerceReducer from './reducers/EcommerceReducer';
import { commonSelectorsApi } from './api/timscontrol/commonSelectorsApi';
import { timsControlCustomApi } from './api/timscontrol/timsControlCustomApi';
import { appModuleApi } from './api/timscontrol/appModuleApi';
import { appTaskApi } from './api/timscontrol/appTaskApi';
import { appMenuApi } from './api/timscontrol/appMenuApi';
import { appRoleApi } from './api/timscontrol/appRoleApi';
import { userCategoryApi } from './api/timscontrol/userCategoryApi';
import { pwdRecoveryOptionApi } from './api/timscontrol/pwdRecoveryOptionApi';
import { contactUsApi } from './api/timscontrol/contactUsApi';
// organization settings import
import { branchApi } from './api/orgsetup/branchApi';
// hr import
import { personalTitleApi } from './api/hrm/personalTitleApi';
import { maritalStatusApi } from './api/hrm/maritalStatusApi';
import { religionApi } from './api/hrm/religionApi';
import { relationshipApi } from './api/hrm/relationshipApi';
import { occupationApi } from './api/hrm/occupationApi';
import { qualificationApi } from './api/hrm/qualificationApi';
import { uploadDocumentTypeApi } from './api/hrm/uploadDocumentTypeApi';
import { empDocApi } from './api/hrm/empDocApi';
import { empDocUploadApi } from './api/hrm/empDocUploadApi';
import { employeeApi } from './api/hrm/employeeApi';
// ecommerce import
import { productCategoryApi } from './api/ecommerce/productCategoryApi';
import { productDetailApi } from './api/ecommerce/productDetailApi';
import { productBrandApi } from './api/ecommerce/productBrandApi';
import { productAttributeApi } from './api/ecommerce/productAttributeApi';
import { productAttributeValueApi } from './api/ecommerce/productAttributeValueApi';
import { productTypeApi } from './api/ecommerce/productTypeApi';
import { productInventoryApi } from './api/ecommerce/productInventoryApi';
import { productStockApi } from './api/ecommerce/productStockApi';
import { orderApi } from './api/ecommerce/orderApi';
import { productDiscountApi } from './api/ecommerce/productDiscountApi';
import { downloadUploadProdStockApi } from './api/ecommerce/downloadUploadProdStockApi';
// Financial Account & Payment
import { paymentMethodApi } from './api/accpay/paymentMethodApi';

// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    authState: authReducer,
    ecommerce: EcommerceReducer,
    [commonSelectorsApi.reducerPath]: commonSelectorsApi.reducer,
    [timsControlCustomApi.reducerPath]: timsControlCustomApi.reducer,
    [appModuleApi.reducerPath]: appModuleApi.reducer,
    [appTaskApi.reducerPath]: appTaskApi.reducer,
    [appMenuApi.reducerPath]: appMenuApi.reducer,
    [appRoleApi.reducerPath]: appRoleApi.reducer,
    [userCategoryApi.reducerPath]: userCategoryApi.reducer,
    [pwdRecoveryOptionApi.reducerPath]: pwdRecoveryOptionApi.reducer,
    [contactUsApi.reducerPath]: contactUsApi.reducer,
    // Organization Setting module
    [branchApi.reducerPath]: branchApi.reducer,
    // HR module
    [personalTitleApi.reducerPath]: personalTitleApi.reducer,
    [maritalStatusApi.reducerPath]: maritalStatusApi.reducer,
    [religionApi.reducerPath]: religionApi.reducer,
    [relationshipApi.reducerPath]: relationshipApi.reducer,
    [occupationApi.reducerPath]: occupationApi.reducer,
    [qualificationApi.reducerPath]: qualificationApi.reducer,
    [empDocApi.reducerPath]: empDocApi.reducer,
    [uploadDocumentTypeApi.reducerPath]: uploadDocumentTypeApi.reducer,
    [empDocUploadApi.reducerPath]: empDocUploadApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    // Ecommerce module
    [productCategoryApi.reducerPath]: productCategoryApi.reducer,
    [productDetailApi.reducerPath]: productDetailApi.reducer,
    [productBrandApi.reducerPath]: productBrandApi.reducer,
    [productAttributeApi.reducerPath]: productAttributeApi.reducer,
    [productAttributeValueApi.reducerPath]: productAttributeValueApi.reducer,
    [productTypeApi.reducerPath]: productTypeApi.reducer,
    [productInventoryApi.reducerPath]: productInventoryApi.reducer,
    [productStockApi.reducerPath]: productStockApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [productDiscountApi.reducerPath]: productDiscountApi.reducer,
    [downloadUploadProdStockApi.reducerPath]: downloadUploadProdStockApi.reducer,
    // Financial Account & Payment
    [paymentMethodApi.reducerPath]: paymentMethodApi.reducer
  },
  devTools: process.env.NODE_ENV === 'development',
  // Add the PostApi middleware to the store
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      customerApi.middleware,
      commonSelectorsApi.middleware,
      timsControlCustomApi.middleware,
      appModuleApi.middleware,
      appTaskApi.middleware,
      appMenuApi.middleware,
      appRoleApi.middleware,
      userCategoryApi.middleware,
      pwdRecoveryOptionApi.middleware,
      contactUsApi.middleware,
      // Organization Setting module
      branchApi.middleware,
      // HR module
      personalTitleApi.middleware,
      maritalStatusApi.middleware,
      religionApi.middleware,
      relationshipApi.middleware,
      occupationApi.middleware,
      qualificationApi.middleware,
      empDocApi.middleware,
      uploadDocumentTypeApi.middleware,
      empDocUploadApi.middleware,
      employeeApi.middleware,
      // Ecommerce module
      productCategoryApi.middleware,
      productDetailApi.middleware,
      productBrandApi.middleware,
      productAttributeApi.middleware,
      productAttributeValueApi.middleware,
      productTypeApi.middleware,
      productInventoryApi.middleware,
      productStockApi.middleware,
      orderApi.middleware,
      productDiscountApi.middleware,
      downloadUploadProdStockApi.middleware,
      // Financial Account & Payment
      paymentMethodApi.middleware
    ])
});
