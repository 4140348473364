import { createApi } from "@reduxjs/toolkit/query/react";
import customHeaderFetchBase from "../customHeaderFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;
const multipartHeader = { headers: { "Content-Type": "multipart/form-data" } };

export const empDocUploadApi = createApi({
  reducerPath: "empDocUploadApi",
  baseQuery: customHeaderFetchBase,
  endpoints: (builder) => ({
    addEmpDocUpload: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/empdocupload/",
        method: "POST",
        body: formData,
        multipartHeader,
      }),
    }),
    deleteEmpDocUpload: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/empdocupload/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useAddEmpDocUploadMutation, useDeleteEmpDocUploadMutation } =
  empDocUploadApi;
