import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBase";
import appConfig from "appconfig";

const apiUrl = appConfig.thiapiBaseUrl + appConfig.modules.hr;

export const uploadDocumentTypeApi = createApi({
  reducerPath: "uploadDocumentTypeApi",
  baseQuery: customFetchBase,
  tagTypes: ["UploadDocType"],
  endpoints: (builder) => ({
    getUploadDocTypes: builder.query({
      query: () => apiUrl + "/uploaddoctype",
      providesTags: ["UploadDocType"],
    }),
    addUploadDocType: builder.mutation({
      query: (formData) => ({
        url: apiUrl + "/uploaddoctype/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["UploadDocType"],
    }),
    updateUploadDocType: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: apiUrl + `/uploaddoctype/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["UploadDocType"],
    }),
    updateUploadDocTypeSortOrder: builder.mutation({
      query: (formData) => ({
        url: appConfig.thiapiBaseUrl + appConfig.modules.tims + "/sortorder/",
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["UploadDocType"],
    }),
    deleteUploadDocType: builder.mutation({
      query: (id) => ({
        url: apiUrl + `/uploaddoctype/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UploadDocType"],
    }),
  }),
});

export const {
  useGetUploadDocTypesQuery,
  useAddUploadDocTypeMutation,
  useUpdateUploadDocTypeMutation,
  useUpdateUploadDocTypeSortOrderMutation,
  useDeleteUploadDocTypeMutation,
} = uploadDocumentTypeApi;
